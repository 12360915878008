import { Dali2PropertiesPropertiesWithTotalEnergy, LampMtap3V1PropertiesPropertiesWithKobize, TelemetryIndicesMap } from '@/types/telemetry';
import { Models } from '@mtap-smartcity/lib-api';

// indices to display in form of circular gauges with min and max values
const lampIndicesNames = [
  'activePower',
  'controlGearPowerFactor',
  'lightSourceTemperature',
  'set_duty',
  'measuredDuty',
  'controlGearExternalSupplyVoltage',
  'lightSourceCurrent',
  'totalEnergy',
  'co2Emission',
];

// indices to display in form of circular gauges with min and max values
const lampMtap3V1IndicesNames = [
  'activePower',
  'powerFactor',
  'setDuty',
  'supplyVoltage',
  'current',
  'totalEnergy',
  'co2Emission',
];

// indices to display in form of circular gauges with min and max values
const lampControlCabinet1V1IndicesNames = [
  'setDuty',
];

// time indices
const timeMeterIndicesNames = [
  'lightSourceOnTime',
  'controlGearOperatingTime',
  'energyConsumption',
  'co2Emission'
];

const lampTelemetryIndices: TelemetryIndicesMap<Dali2PropertiesPropertiesWithTotalEnergy> = {
  activePower: {
    min: 0, max: 0, unit: 'W'
  },
  controlGearPowerFactor: {
    min: 0, max: 1, unit: ''
  },
  lightSourceTemperature: {
    min: -60, max: 163, unit: 'C'
  },
  set_duty: {
    min: 0, max: 100, unit: '%'
  },
  measuredDuty: {
    min: 0, max: 100, unit: '%'
  },
  lightSourceVoltage: {
    unit: 'V'
  },
  controlGearExternalSupplyVoltage: {
    unit: 'V'
  },
  lightSourceCurrent: {
    unit: 'A'
  },
  lightSourceOnTime: {
    unit: 's',
  },
  controlGearOperatingTime: {
    unit: 's'
  },
  energyConsumption: {
    unit: 'kWh'
  },
  totalEnergy: {
    unit: 'kWh',
  },
  co2Emission: {
    unit: 'kg',
  },
};

const lampMtap3V1TelemetryIndices: TelemetryIndicesMap<LampMtap3V1PropertiesPropertiesWithKobize> = {
  activePower: {
    min: 0, max: 0, unit: 'W'
  },
  powerFactor: {
    min: 0, max: 100, unit: ''
  },
  setDuty: {
    min: 0, max: 100, unit: '%'
  },
  supplyVoltage: {
    min: 0, max: 230 * 1.1, unit: 'V'
  },
  status: {
    unit: ''
  },
  current: {
    min: 0, max: 0, unit: 'A'
  },
  totalEnergy: {
    unit: 'kWh',
  },
  co2Emission: {
    unit: 'kg',
  },
};

const lampControlCabinet1V1TelemetryIndices: TelemetryIndicesMap<Models.Telemetries.LampControlCabinet1V1Properties> = {
  address: {
    unit: ''
  },
  setDuty: {
    min: 0, max: 100, unit: '%'
  },
  status: {
    unit: ''
  },
};

export default {
  lampIndicesNames,
  lampMtap3V1IndicesNames,
  lampControlCabinet1V1IndicesNames,
  timeMeterIndicesNames,
  lampTelemetryIndices,
  lampMtap3V1TelemetryIndices,
  lampControlCabinet1V1TelemetryIndices
};
