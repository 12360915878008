













import {
  Component, Vue, Prop
} from 'vue-property-decorator';
import { Models } from '@mtap-smartcity/lib-api';
import { isGateway, isLamp } from '@/utils/type_check';
import ConsumptionCard from './ConsumptionCard.vue';
import { ControllerTypes } from '../AnalyticsCard.vue';

@Component({
  components: {
    ConsumptionCard,
  }
})
/**
 * @group Analytics Card
 * Analytics tab with lamp energy consumption chart
 */
export default class AnalyticsCardAnalyticsTab extends Vue {
  // Selected lamp data object
  @Prop({ type: Object, validator: (d) => isLamp(d) || isGateway(d) }) readonly selectedDevice!: Models.Devices.Lamp | Models.Devices.Gateway | null;

  @Prop({ type: Object }) readonly selectedElement!: Models.Circuits.Model | Models.Groups.Model | null;

  @Prop({
    type: Object,
    // temporary 'any | null' type to be transformed into 'Models.Telemetries.Telemetry | null'
  }) readonly telemetryData!: any | null;

  @Prop({
    type: String,
  }) readonly telemetryControllerType!: ControllerTypes | undefined;
}
